import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProjectHeroSection from '../../components/organism/projectPage/projectHeroSection';
import { IImage, IProjectViewData, IProjectsData, ISection, ITextUrl, ITitleBulletList, ITitleImage, ITitleText } from '../../interface';
import { projectsData } from '../../assets/projects-data';
import ProjectTitleImageContainer from '../../components/organism/projectPage/projectTitleImage';
import ProjectAbout from '../../components/organism/projectPage/projectAbout';
import ProjectText from '../../components/organism/projectPage/projectText';
import UserResearch from '../../components/organism/projectPage/userResearch';
import ProjectUrl from '../../components/organism/projectPage/projectUrl';
import './index.css'
import WhatILearned from '../../components/organism/projectPage/whatILearned';
import Impact from '../../components/organism/projectPage/impact';
import ImageContent from '../../components/organism/projectPage/imageContent';
import GetInTouch from '../../components/organism/getInTouch';
import { ScrollTo } from '../../hooks/scrollTo';
import ViewAnother from '../../components/organism/projectPage/viewAnother';
import ReactGA from "react-ga4";

const Project : React.FC = () => { 
  const [data, setData] = useState<IProjectsData>()
  const { id } = useParams();

  ScrollTo({behavior: 'instant'})


  const getSelectedProjectData = (id: string | undefined) => {
    if(id === undefined) return;

    projectsData.forEach((project: IProjectsData) => {
      if(id.toLowerCase() === project.title.toLowerCase()){
        setData(project)
        ReactGA.send({ hitType: "pageview", page: "/project", title: `${project.title}` });
      }
    });
  }

  useEffect(() => {
    getSelectedProjectData(id)
  }, [id])



  // TODO: 
  // DODAT GRESKU
  if(data === undefined) return <div>Loading...</div>

  return (
    <div className='project-container'>
       <ProjectHeroSection title={data.title} text={data.desciption} />
      {
        data.sections.map((section: ISection, index: number) => {
         
          if(section.type === 'title-image') {
            const typeData = section.typeData as ITitleImage
            
            return (
              <ProjectTitleImageContainer 
                key={index}
                title={typeData.title}
                image={typeData.image}  /> 
            )
          }
           
          if(section.type === "overlaping-cards") {
            const typeData = section.typeData as ITitleText[]
            
            return (
              <ProjectAbout key={index} data={typeData} />
            )
          }
         
          if(section.type === "title-text") {
            const typeData = section.typeData as ITitleText
            
            return (
              <ProjectText 
                className={typeData.title.replace(' ', '-')}
                key={index} title={typeData.title} text={typeData.text} />
            )
          }
          
          if(section.type === "user-research") {
            const typeData = section.typeData as ITitleText[]
            
            return (
              <UserResearch key={index} data={typeData} />
            )
          }
           
          if(section.type === "text-url") {
            const typeData = section.typeData as ITextUrl[]

            return (
              <div key={index} className='project-urls-container'> 
                {
                   typeData.map((element,i) => {
                    return (
                      <ProjectUrl key={`${index}${i}`} urlTitle={element.urlTitle} url={element.url} text={element.text} isFirst={i === 0}/>
                    )
                  })
                }
              </div>
            )
          }

          if(section.type === "title-bullet-list") {
            const typeData = section.typeData as ITitleBulletList

            if(typeData.title.toLowerCase() === 'impact:') {
              return <Impact key={`${index}1`} data={typeData}/>
            }
            
            return (
              <WhatILearned key={index} data={typeData}/>
            )
          }
         
          if(section.type === "image") {
            const typeData = section.typeData as IImage

           
            return (
              <ImageContent key={index} image={typeData.image} imageName={typeData.name}/>
            )
          }
          
          if(section.type === "another-project") {
            const typeData = section.typeData as IProjectViewData[]

           
            return (
              <ViewAnother key={index} data={typeData}/>
            )
          }
           
        })
      }

      
      <GetInTouch />
    </div>
  )
}

export default Project