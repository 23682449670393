import React from 'react';
import './index.css'
import NumberIcon from '../../atom/numberIcon';

interface Props {
  number: number;
  text: string
  title: string
}

const UserResearchElementV2 : React.FC<Props> = ({
  number,
  text,
  title
}) => { 
  // const splitAfterSecondSpace = (text: string) => {
  //   // Split the text by spaces
  //   const parts = text.split(' ');

  //   // Check if there are at least 3 parts (2 spaces)
  //   if (parts.length < 3) {
  //       return [text]; // Return the original text if there are less than 2 spaces
  //   }

  //   // Join the parts after the second space back together
  //   const firstPart = parts.slice(0, 2).join(' ');
  //   const secondPart = parts.slice(2).join(' ');


  //   return [firstPart, secondPart];
  // }

  // const [firstPart, secondPart] = splitAfterSecondSpace(title);


  return (
    <div className='user-research-element-container'>
      <NumberIcon number={number} />
      <div className='user-research-element-title'>
        <h4>
          {title}
        </h4>
        {/* <h4>
          {firstPart}
        </h4>
        <h4>
          {secondPart}
        </h4> */}
        <p>{text}</p>
      </div>
     
      
    </div>
  )
}

export default UserResearchElementV2