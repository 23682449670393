import React from 'react';

interface Props {
}

const CloseIcon : React.FC<Props> = () => { 
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3866 12L20.4178 3.61878C20.5357 3.47949 20.4366 3.26788 20.2545 3.26788H18.117C17.9911 3.26788 17.8705 3.32413 17.7875 3.42056L11.9884 10.334L6.18928 3.42056C6.10892 3.32413 5.98838 3.26788 5.85981 3.26788H3.72231C3.54017 3.26788 3.44106 3.47949 3.55892 3.61878L10.5902 12L3.55892 20.3813C3.53252 20.4123 3.51558 20.4503 3.51012 20.4907C3.50465 20.5311 3.5109 20.5722 3.5281 20.6091C3.5453 20.6461 3.57275 20.6773 3.60717 20.6991C3.6416 20.7209 3.68156 20.7324 3.72231 20.7322H5.85981C5.98571 20.7322 6.10624 20.6759 6.18928 20.5795L11.9884 13.6661L17.7875 20.5795C17.8678 20.6759 17.9884 20.7322 18.117 20.7322H20.2545C20.4366 20.7322 20.5357 20.5206 20.4178 20.3813L13.3866 12Z" fill="black"/>
    </svg>

  )
}

export default CloseIcon