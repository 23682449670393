import React from 'react';
import { ITitleBulletList } from '../../../../interface';
import './index.css'

interface Props {
  data: ITitleBulletList
}

const WhatILearned : React.FC<Props> = ({data}) => { 
  return (
    <div className='container what-i-learned-container'> 
      <div className='what-i-learned-content-container'>

        <h4 className='title-ternary'>{data.title}</h4>
        <div className='what-i-learned-text-container'>
          {
            data.bulletList.map((el: string, i: number) => {
              return <p key={i}>{el}</p>
            })
          }
        </div>
      </div>

    </div>
  )
}

export default WhatILearned