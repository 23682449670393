import React from 'react';
import './index.css'

interface Props {
  title: string,
  text: string[]
  isHome?: boolean
}

const HeroSection : React.FC<Props> = ({isHome = false, title, text}) => { 
  return (
   <div className={`hero-container ${isHome ? 'home-hero-section-container' : ''}`}>
    <div className={`text-container ${!isHome ? 'flex-8' : ''}`}>
      <h1 className='main-title'>{title}</h1>
      {
        text.map((el, i) => (
          <h2 key={i} className='text'>
            {el}
          </h2>
        ))
      }
    </div>
    
    {
      isHome &&
      <div className='image-container'>
        <div> 
          <img src={require('../../../assets/home-profile-picture.png')} alt='hero image'/>
        </div>
      </div>
    }
   </div>
  )
}

export default HeroSection