import React from 'react';

const LinkedInIcon : React.FC = () => { 
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.8803 1.4035H2.19625C1.55781 1.4035 1 1.86288 1 2.49382V14.2038C1 14.8382 1.55781 15.4035 2.19625 15.4035H13.8769C14.5188 15.4035 15 14.8344 15 14.2038V2.49382C15.0037 1.86288 14.5188 1.4035 13.8803 1.4035ZM5.33969 13.0732H3.33406V6.83725H5.33969V13.0732ZM4.40625 5.88913H4.39187C3.75 5.88913 3.33437 5.41132 3.33437 4.81319C3.33437 4.20413 3.76094 3.73757 4.41719 3.73757C5.07344 3.73757 5.475 4.20069 5.48938 4.81319C5.48906 5.41132 5.07344 5.88913 4.40625 5.88913ZM12.6697 13.0732H10.6641V9.6635C10.6641 8.84663 10.3722 8.2885 9.64656 8.2885C9.09219 8.2885 8.76406 8.6635 8.61812 9.02882C8.56344 9.16007 8.54875 9.33882 8.54875 9.52132V13.0732H6.54313V6.83725H8.54875V7.70507C8.84062 7.28944 9.29656 6.69132 10.3575 6.69132C11.6741 6.69132 12.67 7.55913 12.67 9.43007L12.6697 13.0732Z" fill="white"/>
    </svg>
  )
}

export default LinkedInIcon