import React from 'react';
import { ITitleText } from '../../../../interface';
import AboutProject from '../../../molecule/aboutProject';
import MyRole from '../../../molecule/myRole';
import './index.css'
import { getWindowWidth } from '../../../../hooks/getWindowWidth';

interface Props {
  data: ITitleText[]
}

const ProjectAbout : React.FC<Props> = ({data}) => { 
  const {windowWidth} = getWindowWidth()
  return (
    <div className={`${windowWidth >= 576 ? 'container' : ''} project-about-container`}>
      {
        data.map((item: ITitleText, index: number)=> {
          if(item.title === 'About project') {
            return(
              <AboutProject key={index} title={item.title} text={item.text} />
            )
          }
          
          return <MyRole key={index} title={item.title} text={item.text} />
         
        })
      }
    </div>
  )
}

export default ProjectAbout