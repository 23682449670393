import React, { FormEvent, useRef, useState } from 'react';
import './index.css'
import InputField from '../../molecule/inputField';
import Button from '../../molecule/button';
import { sendEmail } from '../../../endpoints/sendMail';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';


const ContactForm : React.FC = () => { 
  const formRef = useRef<HTMLFormElement>(null)
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false)
  const [emailSendStatus, setEmailSendStatus] = useState<"success" | "error" | null>(null)

  const sendEmailToUseer = async (e: FormEvent, formRef: React.RefObject<HTMLFormElement>) => {
    setBtnDisabled(true)
    sendEmail(e, formRef)
      .then(() => {
        setEmailSendStatus("success")
        setTimeout(() => {
          setEmailSendStatus(null)
          setBtnDisabled(false)
        }, 5000)
      })
      .catch((err) => {
        setEmailSendStatus("error")
        console.log(err.text);
      })
  }

  const renderButton = () => {
    switch (emailSendStatus) {
      case 'success':
        return  <DotLottieReact
        src="https://lottie.host/c4f2dd95-a240-49c2-830f-1a2f90e2d0e8/VHnUhGTA7Q.json"
        loop={false}
        autoplay 
        speed={1} 
        style={{
          width: 100,
          height: 100
        }}
      />
      case 'error':
        return <Button disabled={btnDisabled} type='submit' backgroundColor='#0500FF' title='Submit' />
      default:
        return <Button disabled={btnDisabled} type='submit' backgroundColor='#0500FF' title='Submit' />
    }
  }

  return (
    <form ref={formRef} className='contact-form-container' onSubmit={(e: FormEvent) => sendEmailToUseer(e, formRef)}>
      <InputField name='user_name' label='Name' inputType='text' />
      <InputField name='user_email' label='Email' inputType='email' />
      <InputField name='message' label='Message' inputType='textarea' />

      {
        renderButton()
      }
    </form>
  )
}

export default ContactForm