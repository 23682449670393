import React from 'react';
import './index.css'

const Footer : React.FC = () => { 
  return (
    <div className='footer-container'>
      <p>2024 Designed by Antonija Luketić / <a href='https://mcode.hr/'target='_blank' rel="noreferrer">Powered by MCODE</a></p>
    </div>
  )
}

export default Footer