import { IProjectsData } from "../interface";

export const projectsData :IProjectsData[] = [
  {
    title: "Raspored vlakova Hrvatske",
    desciption: "Designing this app allowed me to tackle user experience challenges, enhance my skills in wireframing and prototyping, and collaborate closely with developer. See how we improved travel efficiency, addressed user pain points, and created an intuitive interface that has already garnered 3,000 downloads on Google Play. Explore the journey of transforming the travel experience for thousands of commuters and tourists, and see the impact of our user-centered design approach.",
    sections: [
      // {
      //   type: "main-title",
      //   typeData: {
      //     title: 'Design a mobile app for all train travelers in Croatia',
      //     image: require('../assets/hz-project/hz-main.png')
      //   }
      // },
      {
        type: "title-image",
        typeData: {
          title: 'Design a mobile app for all train travelers in Croatia',
          image: require('../assets/hz-project/hz-main.png')
        }        
      },
      {
        type: 'overlaping-cards',
        typeData: [
          {
            title: "About project",
            text: [ 
              `Raspored vlakova Hrvatske is an app intended for all travelers within Croatia. It offers efficiency in journey planning, convenient ticket purchasing, real-time updates and an enhanced user experience.`,
              "The goal was provide passengers with a simple and reliable way to track train schedules, purchase tickets, and receive information about any delays, ensuring that train travel is as comfortable and stress-free as possible."
            ]
          },
          {
            title: "My role and responsibilities",
            text: [
              'UX designer designing an Raspored vlakova Hrvatske app from conception to delivery.',
              "Conducting interviews, paper and digital wireframing, low and high-fidelity prototyping, conducting usability studies, accounting for accessibility and iterating on designs."
            ]
          },
        ]
      },
      {
        type: 'title-text',
        typeData: {
          title: 'Users',
          text: [
            'User research focused on understanding the needs and preferences of individuals who frequently travel by train.',
            'I conducted a combination of online surveys and in-person interviews with a diverse group of train travelers across different demographics, including age, occupation, and travel frequency. The research was carried out over a span of two months, involving over 100 participants.'
          ]
        }
      },
      {
        type: 'user-research',
        typeData: [
          {
            title: 'Limited real-time informations',
            text: 'Train travelers frequently face challenges in obtaining accurate and up-to-date information about train schedules, delays, and platform changes.'
          },
          {
            title: "Too much informations",
            text: 'The existing app contains too much irrelevant information that can confuse users.'
          },
          {
            title: 'Inconvenient ticket booking process',
            text: 'Traditional methods of purchasing train tickets, such as queuing at ticket counters or navigating complex booking websites, can be time-consuming and cumbersome.'
          }
        ]
      },
      {
        type: 'title-image',
        typeData: {
          title: 'User persona',
          image: require('../assets/hz-project/user-persona.png')
        }
      },
      {
        type: "title-text",
        typeData: {
          title: 'Digital wireframes',
          text: `Digital wireframes provided a structured visual blueprint that allowed me to experiment with different layouts, user interfaces, and features. It helped me quickly iterate and test various design ideas, ensuring the app's user experience was intuitive and efficient.`
        }
      }, 
      {
        type: 'image',
        typeData: {
          image: require('../assets/hz-project/digital-wireframe.png'),
          name: 'wireframe'
        }
      },
      {
        type: 'text-url',
        typeData: [
          {
            text: 'View the Raspored vlakova Hrvatske',
            url: 'https://www.figma.com/proto/Ugj4HY9ECJ19m3hHAguwup/hzpp-app?node-id=787-1518&t=xrhANyAGJa19TRL9-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=787%3A1518&show-proto-sidebar=1',
            urlTitle: 'Low-fidelity prototype'
          }
        ]
      }, 
      {
        type: 'title-text',
        typeData: {
          title: 'Mockups',
          text: "Digital wireframes helped me map out how different parts of the app would work together. They allowed me to sketch out things like train schedules, ticket buying, and updates about delays in a clear and organized way. By using digital wireframes, I could easily test out different ideas and make sure the app was easy to use for everyone."
        }
      },
      {
        type: 'image',
        typeData: {
          image: require('../assets/hz-project/mockup.png'),
          name: 'mockup'
        }
      },
      {
        type: 'text-url',
        typeData: [
          {
            text: 'View the Raspored vlakova Hrvatske',
            url: 'https://www.figma.com/proto/Ugj4HY9ECJ19m3hHAguwup/hzpp-app?node-id=708-1519&t=Cdhv3oAPT0xrh8SN-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=708%3A1519&show-proto-sidebar=1',
            urlTitle: 'High-fidelity prototype'
          },
          {
            text: 'Download Raspored vlakova Hrvatske on Android',
            url: 'https://play.google.com/store/apps/details?id=com.hzppapp&hl=hr',
            urlTitle: 'Play Store'

          },
          {
            text: 'Download Raspored vlakova Hrvatske on iOS',
            url: 'https://apps.apple.com/us/app/raspored-vlakova-hrvatske/id6504168712',
            urlTitle: 'App Store'

          }
        ]
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'What I learned:',
          bulletList: [
            "Emphasized the importance of understanding and addressing user needs through extensive research and usability testing.",
            "Gained insights into technical feasibility by working closely with developer.",
            "Learned the value of continuous feedback and refinement to enhance the final product.",
            "Adapted designs to meet technical limitations while maintaining usability.",
            "Enhanced ability to communicate design concepts and rationale effectively to team members."
          ]
        }
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'Impact:',
          bulletList: [
            "Achieved 3,000 downloads on the Google Play Store, demonstrating strong user interest and adoption.",
            "Facilitated easier and more efficient route planning for train travelers in Croatia.",
            "Provided real-time schedules and user-friendly navigation, enhancing the overall travel experience.",
            "Received favorable reviews, indicating high user satisfaction and app reliability.",
          ]
        }
      },
      {
        type: 'another-project',
        typeData: [
          {
            title: 'SnackFlix',
            description: 'User Experience and User Interface Design for SnackFlix to Revolutionize Movie Snack Delivery.',
            image: require('../assets/snackflix-image.png'),
            color: '#D98200',
            to: '/project/snackflix'

          },
          {
            title: 'MCODE',
            description: 'User Experience, Web app design and UI for small startup, currently operating in Croatia.',
            image: require('../assets/mcode-image.png'),
            color: '#160057',
            // to: '/project/mcode'
            to: 'https://mcode.hr/'
          }
        ]
      }
    ]
  },


  {
    title: "SnackFlix",
    desciption: "By streamlining snack orders, SnackFlix enhances user convenience, boosts cinema attendance, and elevates customer satisfaction. This innovative approach helps cinemas achieve their business objectives by increasing revenue and delivering a superior movie-watching experience to film enthusiasts everywhere.",
    sections: [
      {
        type: "main-title",
        typeData: {
          title: 'Design a mobile app for all train travelers in Croatia',
          image: require('../assets/snackflix-project/snackflix-main.png')
        }
      },
      {
        type: "title-image",
        typeData: {
          title: 'Design a mobile app for all train travelers in Croatia',
          image: require('../assets/snackflix-project/snackflix-main.png')
        }        
      },
      {
        type: 'overlaping-cards',
        typeData: [
          {
            title: "About project",
            text: [ 
              `Designed for busy moviegoers, SnackFlix allows users to effortlessly pre-order their favorite snacks and beverages from their seats or even before arriving at the theater. By eliminating the hassle of long queues and ensuring timely delivery to their seats, SnackFlix enhances the overall cinema experience.`            
            ]
          },
          {
            title: "My role and responsibilities",
            text: [
              'As the UX designer for SnackFlix, my role involved spearheading the entire design process from inception to launch. This encompassed conducting user interviews to understand moviegoers needs, preferences, and pain points.',
              "I crafted paper and digital wireframes to visualize the app's structure and flow, followed by developing low and high-fidelity prototypes for iterative testing and refinement.",
              'Throughout the design phase, I prioritized accessibility considerations to ensure all users could navigate the app seamlessly.',
              'Conducting usability studies allowed me to gather invaluable feedback, which guided continuous improvements to the design.'
            ]
          },
        ]
      },
      {
        type: 'title-text',
        typeData: {
          title: 'Users',
          text: [
            'User research primarily focused on busy workers who frequently attend movie theaters and face time constraints when it comes to waiting in long lines.',
            'Initially, I assumed that these users were primarily concerned with speed and convenience. However, after conducting research, I discovered that their needs extended beyond this, with a strong emphasis on personalization, movie-themed promotions, and seamless payment options.'
          ]
        }
      },
      {
        type: 'user-research',
        typeData: [
          {
            title: 'Time',
            text: 'Working people and families with children do not have time to wait in long lines.'
          },
          {
            title: "Accessibility",
            text: 'Platforms for ordering food are not equipped with assistive technologies.'
          },
          {
            title: 'Nutritional information',
            text: 'Many food ordering apps do not provide enough information about the food (allergens, calories, proteins, etc.)'
          }
        ]
      },
      {
        type: 'title-image',
        typeData: {
          title: 'User persona',
          image: require('../assets/snackflix-project/user-persona.png')
        }
      },
      {
        type: "title-text",
        typeData: {
          title: 'Digital wireframes',
          text: "Digital wireframes were essential in mapping out how various components of SnackFlix would interact. They enabled me to clearly sketch out features like snack selection, order tracking, and seat delivery options in an organized manner. By utilizing digital wireframes, I could efficiently test different concepts and ensure the app's usability for all users."
        }
      }, 
      {
        type: 'image',
        typeData: {
          image: require('../assets/snackflix-project/digital-wireframe.png'),
          name: 'wireframe'
        }
      },
      {
        type: 'text-url',
        typeData: [
          {
            text: 'View the Snackflix',
            url: 'https://www.figma.com/proto/HtJlmfQx74jPazmCor0pBL/Untitled?node-id=146-1240&t=J4Nx2dvTwbZR9Ar8-1&scaling=min-zoom&content-scaling=fixed&page-id=146%3A34&starting-point-node-id=146%3A35',
            urlTitle: 'Low-fidelity prototype'
          }
        ]
      }, 
      {
        type: 'title-text',
        typeData: {
          title: 'Mockups',
          text: [
            `During the first usability study, users expressed dissatisfaction that there was only an option for delivering snacks to the seat. Taking that into account, I created the “pick up at the counter” button so that users have more options at their disposal.`,
            `Incorporating a "Deliver to Seat" button is immensely beneficial as it saves users valuable time and enhancing their overall satisfaction. This feature caters specifically to target audience of busy moviegoers who don't have time to wait in lines.`

          ]
        }
      },
      {
        type: 'image',
        typeData: {
          image: require('../assets/snackflix-project/testing-result-image.png'),
          name: 'mockup'
        }
      },
      {
        type: 'image',
        typeData: {
          image: require('../assets/snackflix-project/mockup-2.png'),
          name: 'mockup'
        }
      },
      {
        type: 'text-url',
        typeData: [
          {
            text: 'View the Snackflix',
            url: 'https://www.figma.com/proto/HtJlmfQx74jPazmCor0pBL/Untitled?node-id=204-310&t=Y6qgpn0m5Mmsj5Rf-1&scaling=min-zoom&content-scaling=fixed&page-id=204%3A5&starting-point-node-id=204%3A150',
            urlTitle: 'High-fidelity prototype'
          }
        ]
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'What I learned:',
          bulletList: [
            "Gained hands-on experience in the complete UX design process from conception to delivery.",
            "Learned the importance of user research through interviews to identify key needs and pain points.",
            "Developed skills in creating paper and digital wireframes to visualize app structure and functionality.",
            "Enhanced proficiency in building low and high-fidelity prototypes for iterative testing and refinement.",
            "Understood the critical role of accessibility in designing inclusive user experiences.",
            "Conducted usability studies to gather feedback and make data-driven design decisions.",
            "Improved ability to work independently, managing all aspects of the UX design process for my first project."
          ]
        }
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'Impact:',
          bulletList: [
            "Reduced wait times and eliminated the need to stand in long queues.",
            "Enhanced overall cinema experience with timely seat delivery of snacks.",
            "Boosted cinema attendance by offering a unique and attractive service.",
            "Helped cinemas achieve their business objectives by enhancing customer service and operational efficiency.",
          ]
        }
      },
      {
        type: 'another-project',
        typeData: [
          {
            title: 'Raspored vlakova Hrvatske',
            description: 'Design a mobile app for all train travelers in Croatia.',
            image: require('../assets/hz-image.png'),
            color: 'rgb(5, 0, 255)',
            to: '/project/raspored vlakova hrvatske'
          },
          {
            title: 'MCODE',
            description: 'User Experience, Web app design and UI for small startup, currently operating in Croatia.',
            image: require('../assets/mcode-image.png'),
            color: '#160057',
            // to: '/project/mcode'
            to: 'https://mcode.hr/'
            
          }
        ]
      }
    ]
  },

  {
    title: "MCODE",
    desciption: "By streamlining snack orders, SnackFlix enhances user convenience, boosts cinema attendance, and elevates customer satisfaction. This innovative approach helps cinemas achieve their business objectives by increasing revenue and delivering a superior movie-watching experience to film enthusiasts everywhere.",
    sections: [
      {
        type: "main-title",
        typeData: {
          title: 'Design a mobile app for all train travelers in Croatia',
          image: require('../assets/mcode-project/mcode-main.png')
        }
      },
      {
        type: "title-image",
        typeData: {
          title: 'Design a mobile app for all train travelers in Croatia',
          image: require('../assets/mcode-project/mcode-main.png')
        }        
      },
      {
        type: 'overlaping-cards',
        typeData: [
          {
            title: "About project",
            text: [ 
              `Designed for busy moviegoers, SnackFlix allows users to effortlessly pre-order their favorite snacks and beverages from their seats or even before arriving at the theater. By eliminating the hassle of long queues and ensuring timely delivery to their seats, SnackFlix enhances the overall cinema experience.`            
            ]
          },
          {
            title: "My role and responsibilities",
            text: [
              'As the UX designer for SnackFlix, my role involved spearheading the entire design process from inception to launch. This encompassed conducting user interviews to understand moviegoers needs, preferences, and pain points.',
              "I crafted paper and digital wireframes to visualize the app's structure and flow, followed by developing low and high-fidelity prototypes for iterative testing and refinement.",
              'Throughout the design phase, I prioritized accessibility considerations to ensure all users could navigate the app seamlessly.',
              'Conducting usability studies allowed me to gather invaluable feedback, which guided continuous improvements to the design.'
            ]
          },
        ]
      },
      {
        type: 'title-text',
        typeData: {
          title: 'Users',
          text: [
            'User research primarily focused on busy workers who frequently attend movie theaters and face time constraints when it comes to waiting in long lines.',
            'Initially, I assumed that these users were primarily concerned with speed and convenience. However, after conducting research, I discovered that their needs extended beyond this, with a strong emphasis on personalization, movie-themed promotions, and seamless payment options.'
          ]
        }
      },
      {
        type: 'user-research',
        typeData: [
          {
            title: 'Time',
            text: 'Working people and families with children do not have time to wait in long lines.'
          },
          {
            title: "Accessibility",
            text: 'Platforms for ordering food are not equipped with assistive technologies.'
          },
          {
            title: 'Nutritional information',
            text: 'Many food ordering apps do not provide enough information about the food (allergens, calories, proteins, etc.)'
          }
        ]
      },
      {
        type: 'title-image',
        typeData: {
          title: 'User persona',
          image: require('../assets/snackflix-project/user-persona.png')
        }
      },
      {
        type: "title-text",
        typeData: {
          title: 'Digital wireframes',
          text: "Digital wireframes were essential in mapping out how various components of SnackFlix would interact. They enabled me to clearly sketch out features like snack selection, order tracking, and seat delivery options in an organized manner. By utilizing digital wireframes, I could efficiently test different concepts and ensure the app's usability for all users."
        }
      }, 
      {
        type: 'image',
        typeData: {
          image: require('../assets/snackflix-project/digital-wireframe.png'),
          name: 'wireframe'
        }
      },
      {
        type: 'text-url',
        typeData: [
          {
            text: 'View the Raspored vlakova Hrvatske',
            url: 'https://mcode.hr',
            urlTitle: 'Low-fidelity prototype'
          }
        ]
      }, 
      {
        type: 'title-text',
        typeData: {
          title: 'Mockups',
          text: [
            `During the first usability study, users expressed dissatisfaction that there was only an option for delivering snacks to the seat. Taking that into account, I created the “pick up at the counter” button so that users have more options at their disposal.`,
            `Incorporating a "Deliver to Seat" button is immensely beneficial as it saves users valuable time and enhancing their overall satisfaction. This feature caters specifically to target audience of busy moviegoers who don't have time to wait in lines.`

          ]
        }
      },
      {
        type: 'image',
        typeData: {
          image: require('../assets/mcode-project/mcode-mockups.png'),
          name: 'mockup'
        }
      },
      {
        type: 'image',
        typeData: {
          image: require('../assets/snackflix-project/mockup-2.png'),
          name: 'mockup'
        }
      },
      {
        type: 'text-url',
        typeData: [
          {
            text: 'View the Snackflix',
            url: 'https://mcode.hr',
            urlTitle: 'High-fidelity prototype'
          }
        ]
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'What I learned:',
          bulletList: [
            "Gained hands-on experience in the complete UX design process from conception to delivery.",
            "Learned the importance of user research through interviews to identify key needs and pain points.",
            "Developed skills in creating paper and digital wireframes to visualize app structure and functionality.",
            "Enhanced proficiency in building low and high-fidelity prototypes for iterative testing and refinement.",
            "Understood the critical role of accessibility in designing inclusive user experiences.",
            "Conducted usability studies to gather feedback and make data-driven design decisions.",
            "Improved ability to work independently, managing all aspects of the UX design process for my first project."
          ]
        }
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'Impact:',
          bulletList: [
            "Reduced wait times and eliminated the need to stand in long queues.",
            "Enhanced overall cinema experience with timely seat delivery of snacks.",
            "Boosted cinema attendance by offering a unique and attractive service.",
            "Helped cinemas achieve their business objectives by enhancing customer service and operational efficiency.",
          ]
        }
      },
      {
        type: 'another-project',
        typeData: [
          {
            title: 'Raspored vlakova Hrvatske',
            description: 'Design a mobile app for all train travelers in Croatia.',
            image: require('../assets/snackflix-image.png'),
            color: '#D98200',
            to: '/project/snackflix'
          },
          {
            title: 'MCODE',
            description: 'User Experience, Web app design and UI for small startup, currently operating in Croatia.',
            image: require('../assets/snackflix-image.png'),
            color: '#160057',
            to: '/project/mcode'
          }
        ]
      }
    ]
  }
]