import React from 'react';
import './index.css'
import FactItem from '../../molecule/factItem';

interface Props {
}

const QuickFacts : React.FC<Props> = () => { 
  return (
    <div className='facts-container'>
      <div className='container'>
        <h2 className='facts-container-title title-secondary'>Quick facts about me:</h2>
        <div className='facts-container-facts'>
          <FactItem icon='🏡' text='Based in Bjelovar, Croatia' />
          <FactItem isImage icon={require('../../../assets/Google-cert.png')} text='Obtained Google UX certificate' />
          <FactItem icon='👩🏻‍💻' text='Freelancing for more than 2 years' />
          <FactItem icon='🚀' text='Designed the app that is being used by 3k+ users' />
          <FactItem icon='🤝' text='Closely collaborated with developer ' />
          <FactItem icon='🐶' text='Owner of a frenchie ' />
        </div>
      </div>

    </div>
  )
}

export default QuickFacts