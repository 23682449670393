import React from 'react';

const EmailIcon : React.FC = () => { 
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.25 2.9035H2.75C2.28602 2.904 1.84119 3.08853 1.51311 3.41661C1.18503 3.7447 1.0005 4.18953 1 4.6535V12.1535C1.0005 12.6175 1.18503 13.0623 1.51311 13.3904C1.84119 13.7185 2.28602 13.903 2.75 13.9035H13.25C13.714 13.903 14.1588 13.7185 14.4869 13.3904C14.815 13.0623 14.9995 12.6175 15 12.1535V4.6535C14.9995 4.18953 14.815 3.7447 14.4869 3.41661C14.1588 3.08853 13.714 2.904 13.25 2.9035ZM12.8069 5.79819L8.30688 9.29819C8.21912 9.36641 8.11115 9.40344 8 9.40344C7.88885 9.40344 7.78088 9.36641 7.69312 9.29819L3.19313 5.79819C3.14025 5.75826 3.09585 5.70823 3.06248 5.65099C3.02911 5.59375 3.00746 5.53045 2.99877 5.46477C2.99007 5.39909 2.99452 5.33234 3.01185 5.26839C3.02919 5.20445 3.05905 5.14458 3.09972 5.09228C3.14039 5.03998 3.19104 4.99628 3.24874 4.96372C3.30645 4.93116 3.37004 4.9104 3.43584 4.90263C3.50164 4.89487 3.56832 4.90025 3.63201 4.91848C3.69571 4.93671 3.75515 4.96742 3.80687 5.00882L8 8.27007L12.1931 5.00882C12.298 4.92964 12.4298 4.89491 12.56 4.91214C12.6903 4.92938 12.8085 4.99719 12.8892 5.10092C12.9698 5.20464 13.0064 5.33594 12.991 5.46643C12.9756 5.59691 12.9095 5.71609 12.8069 5.79819Z" fill="white"/>
    </svg>
    
  )
}

export default EmailIcon