import React from 'react';
import './App.css';
import {
  Outlet,
} from "react-router-dom";
import Navbar from './components/organism/navbar';
import './styles/index.css'
import Footer from './components/organism/footer';
import './firebase-init.js'
import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize("G-S148JQ7NMD");

  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  )
}

export default App;
