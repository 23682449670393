import React from 'react';
import './index.css'

interface Props {
  icon: string;
  text: string;
  isImage?: boolean;
}

const FactItem : React.FC<Props> = ({icon, text, isImage = false}) => { 
  return (
    <div className='fact-item-container'>
      {
        isImage ? <img className='fact-item-image title-ternary' src={icon} alt={text} /> : <p className='fact-item-icon title-ternary'>{icon}</p>
      }
      <p className='fact-item-text title-ternary'>{text}</p>
    </div>
  )
}

export default FactItem