import React from 'react';
import './index.css'

interface Props {
  title: string,
  text: string[] | string
}

const ProjectHeroSection : React.FC<Props> = ({title, text}) => { 
  return (
   <div className='project-hero-container container'>
    <div className={`project-text-container`}>
      <h1 className='project-main-title title-secondary'>{title}</h1>
      {
        typeof text === 'string' ? (
          <h2 className='text project-text'>{text}</h2>
        ) :
        text.map((el, i) => (
          <h2 key={i} className='text project-text'>
            {el}
          </h2>
        ))
      }
    </div>
   </div>
  )
}

export default ProjectHeroSection