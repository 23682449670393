import React from 'react';
import './index.css'

interface Props {
  image: string;
  imageName: "mockup" | 'wireframe'
}

const ImageContent : React.FC<Props> = ({image, imageName}) => { 
  return (
   <div className={`project-image-container ${imageName === 'wireframe' && 'container project-image-padding'}`}>
    <img src={image} />
   </div>
  )
}

export default ImageContent