import React from 'react';

interface Props {
  dark?: boolean;
}

const TwitterIcon : React.FC<Props> = ({dark = false}) => { 
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.501 8.95802C37.0943 9.56923 35.606 9.97191 34.083 10.1533C35.6835 9.21613 36.8872 7.72721 37.4682 5.96583C35.955 6.85067 34.3022 7.47151 32.5807 7.80177C31.8557 7.04203 30.9839 6.43763 30.0181 6.02532C29.0523 5.61301 28.0128 5.40139 26.9627 5.40333C22.7111 5.40333 19.2705 8.79396 19.2705 12.9736C19.2675 13.555 19.3341 14.1347 19.4689 14.7002C16.4203 14.5573 13.435 13.7796 10.7041 12.4168C7.97319 11.0541 5.55682 9.13627 3.60957 6.78615C2.92643 7.9378 2.56514 9.25181 2.56348 10.5908C2.56348 13.2158 3.93301 15.5361 6.00098 16.8955C4.77576 16.8664 3.57566 16.5422 2.50254 15.9502V16.044C2.50254 19.7158 5.15879 22.7705 8.67441 23.4658C8.01331 23.6421 7.33205 23.7314 6.64785 23.7315C6.16237 23.7323 5.67798 23.6852 5.20176 23.5908C6.1791 26.5986 9.02363 28.7861 12.3932 28.8486C9.65518 30.9587 6.29365 32.0992 2.83691 32.0908C2.22333 32.0899 1.61032 32.0534 1.00098 31.9815C4.51752 34.2269 8.60523 35.4147 12.7775 35.4033C26.9463 35.4033 34.6869 23.8643 34.6869 13.8565C34.6869 13.5283 34.6783 13.2002 34.6627 12.8799C36.1651 11.8112 37.4649 10.4831 38.501 8.95802Z" fill={dark? 'black': "white"}/>
    </svg>
    
  )
}

export default TwitterIcon