import React from 'react';

interface Props {
}

const ChevronRight : React.FC<Props> = () => { 
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.50948 18.8309L15.5095 12.8309L9.50948 6.8309" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ChevronRight