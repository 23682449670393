/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './index.css'
import { IProjectViewData } from '../../../../interface';
import ProjectCard from '../../projectCard';
import { getWindowWidth } from '../../../../hooks/getWindowWidth';
import ChevronLeft from '../../../atom/chevronLeft';
import ChevronRight from '../../../atom/chevronRight';

interface Props {
  data: IProjectViewData[]
}

const ViewAnother : React.FC<Props> = ({
  data
}) => { 
  const {windowWidth} = getWindowWidth()

  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const nextIndex = () => {
    setCurrentIndex((index: number) => {
      if(index === data.length - 1) return 0
      return index + 1
    })
  }
  
  const previousIndex = () => {
    setCurrentIndex((index: number) => {
      if(index === 0) return data.length - 1
      return index - 1
    })

  }

  useEffect(() => {
  },[])

  return (
    <div className="carousel-container">
      <div className='container'>
        <h3 className='title-secondary view-another-title'>Another Project</h3>
      </div>
      <div className='carousel-controls'>
        <div onClick={() => previousIndex()}>
          <ChevronLeft />
        </div>
        <div onClick={() => nextIndex()}>
          <ChevronRight />
        </div>
      </div>
      <div className="carousel-track">
        {
          data.map((item: IProjectViewData, index: number) => {
            return (
              <div className='carousel-item' key={index} style={{
                // marginLeft: `${120 * index}%`,
                // marginLeft: `${(windowWidth* 0.8) * index}px`,
                marginLeft: `${(windowWidth* 0.9) * index}px`,
                translate: `${-(113) * currentIndex}%`,
              }}>
                <ProjectCard 
                  title={item.title}
                  text={item.description}
                  imgUri={item.image} 
                  backgroundColor={item.color}
                  navigateTo={item.to}
                  imageLeft={windowWidth <= 991 ? true : true}
                  btnText={item.title.toLowerCase() === 'mcode' ? 'Visit website' : "See more"}
                />
              </div>
            )
          })
        }
        {/* <div className='carousel-item'>
          <ProjectCard 
            title='Raspored vlakova Hrvatske'
            text='Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra urna id enim facilisis sagittis. Nulla facilisi. Quisque vel eleifend purus.'
            imgUri={require('../../../../assets/hz-image.png')} 
            backgroundColor={'#0500FF'}
            navigateTo={'/project/raspored vlakova hrvatske'}
            imageLeft={windowWidth <= 991 ? true : true}
          /> 
        </div> */}
      </div>
    </div>
  );
}

export default ViewAnother