import React, { useEffect, useState } from 'react';
import './index.css'
import { changeNavbarRoute } from '../../../helpers/windowLocation';
import { Link, useLocation } from 'react-router-dom';
import HamburgerSvg from '../../atom/hamburgerSvg';
import CloseIcon from '../../atom/closeIcon';
import { scrollToTop } from '../../../hooks/scrollTo';

const Navbar : React.FC = () => { 
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)

  const showStickyNavbar = () => {
    const navbar = document.querySelector('.navbar-sticky-container') as HTMLElement

    const lastKnownScrollPosition = window.scrollY;

    if (lastKnownScrollPosition >= 200 && navbar) {
      navbar.classList.add('navbar-sticky-active')
      navbar.classList.remove('navbar-sticky-inactive')
    } else if(lastKnownScrollPosition <= 200 && navbar) {
      navbar.classList.remove('navbar-sticky-active')
      navbar.classList.add('navbar-sticky-inactive')
    }

  } 


  useEffect(() => {
    window.addEventListener('scroll', showStickyNavbar)
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth))

    return () => {
      window.removeEventListener('scroll', showStickyNavbar)
      window.removeEventListener('resize', () => setWindowWidth(window.innerWidth))
    }
  }, [])

  useEffect(() => {
    changeNavbarRoute(location.pathname)
    closeNavMenu()
  }, [location])

  console.log(windowWidth)

  const openNavMenu = async () => {
    const navMenu = document.querySelector('.navbar-drawer-container')

    if(navMenu) {
      navMenu.classList.remove('navbar-drawer-hidden')
      navMenu.classList.add('navbar-drawer-visible')

      // const navMenuLinks = document.querySelector('.navbar-menu-content')
      // navMenu.classList.remove('navbar-menu-hidden')
      // navMenu.classList.add('navbar-menu-visible')
  
      // const timer = (ms: number) => new Promise(res => setTimeout(res, ms))

      // if(navMenuLinks) {
      //   for(let i = 0; i < navMenuLinks.children.length; i++ ) {
      //     console.log(navMenuLinks.children[i])
      //     navMenuLinks.children[i].classList.add('link-nav-menu-visible')
      //     await timer(200)
      //   }
      // }
    }
  }
  

  const closeNavMenu = async () => {
    const navMenu = document.querySelector('.navbar-drawer-container')
    if(navMenu) {
      navMenu.classList.add('navbar-drawer-hidden')
      navMenu.classList.remove('navbar-drawer-visible')
      // const navMenuLinks = document.querySelector('.navbar-menu-content')

      // setTimeout(() => {
      //   if(navMenuLinks) {
      //     for(let i = 0; i < navMenuLinks.children.length; i++ ) {
      //       console.log(navMenuLinks.children[i])
      //       navMenuLinks.children[i].classList.remove('link-nav-menu-visible')
      //     }
      //   }
      // }, 400)
    }
  }

  return (
    <div className='container navbar-container'>
      <div className='al-logo-container'>
        <Link to='/' onClick={() => scrollToTop()}>
          <img src={require('../../../assets/al-logo.png')} alt='AL logo'/>
        </Link>
      </div>
      {
        windowWidth >= 786 ?
        <div className='links-container'>
          <div id='about' className='link about'>
            <p>
              <Link to='/'>
                About
              </Link>
            </p>
            <div className='link-underline'/>
          </div>
          <div id='work' className='link work'>
            <p>
              <Link to='/work'>
                Work
              </Link>
            </p>
            <div className='link-underline'/>
          </div>
          <div id='contact' className='link contact'>
            <p>
              <Link to='/contact'>
                Contact
              </Link>
            </p>
            <div className='link-underline'/>
          </div>

       
        </div>
        : 
        <div className='navbar-open-btn'>
          <HamburgerSvg onClick={() => {
            openNavMenu()
          }} />
        </div>  
      }

      {
        <div className='navbar-sticky-container'>
          <div className='container navbar-sticky-content-container'>
            <div className='al-logo-container'>
              <Link to='/' onClick={() => scrollToTop()}>
                <img src={require('../../../assets/al-logo.png')} alt='AL logo'/>
              </Link>
            </div>
            {
              windowWidth >= 786 ?
              <div className='links-container'>
                <div id='about' className='link about'>
                  <p>
                    <Link to='/'>
                      About
                    </Link>
                  </p>
                  <div className='link-underline'/>
                </div>
                <div id='work' className='link work'>
                  <p>
                    <Link to='/work'>
                      Work
                    </Link>
                  </p>
                  <div className='link-underline'/>
                </div>
                <div id='contact' className='link contact'>
                  <p>
                    <Link to='/contact'>
                      Contact
                    </Link>
                  </p>
                  <div className='link-underline'/>
                </div>

              
              </div> : 
              <div className='navbar-open-btn'>
                <HamburgerSvg onClick={() => {
                  openNavMenu()
                }} />
              </div>
            }

          </div>
        </div>
      }

      <div className='navbar-drawer-container navbar-drawer-hidden'>
        <div className='navbar-drawer-close-icon-container' onClick={() => closeNavMenu()}>
          <CloseIcon />
        </div>

        <div className='navbar-drawer-items-container'>
          <div id='contact' className='link contact'>
            <p className='title-ternary'>
              <Link to='/'>
                About
              </Link>
            </p>
          </div>
          
          <div className='divider'/>

          <div id='contact' className='link contact'>
            <p className='title-ternary'>
              <Link to='/work'>
                Work
              </Link>
            </p>
          </div>

          <div className='divider'/>

          <div id='contact' className='link contact'>
            <p className='title-ternary'>
              <Link to='/contact'>
                Contact
              </Link>
            </p>
          </div>

          <div className='divider'/>

        </div>
      </div>
    </div>
  )
}

export default Navbar