import { useEffect } from "react"

interface Props {
  top?: number;
  behavior?: 'instant' | 'smooth';
}

export const ScrollTo = ({top = 0, behavior = 'smooth'} : Props) => {
  useEffect(() => {
    window.scrollTo({top: top, behavior: behavior as ScrollBehavior})
  }, [top, behavior])
}

export const scrollToTop = (behavior: ScrollBehavior | undefined = 'smooth') => {
  window.scrollTo({top: 0, behavior: behavior})
}