import React from 'react';
import './index.css'

interface Props {
  text: string;
  icon: JSX.Element;
  url: string
}

const SocialInfo : React.FC<Props> = ({text = "text", icon, url}) => { 
  return (
    <div className='social-info-container'>
      {
        icon
      }

      <p><a href={url} target='_blank' rel="noreferrer">{text}</a></p>
    </div>
  )
}

export default SocialInfo