import React, { useEffect } from 'react';
import HeroSection from '../../components/organism/heroSection';
import ContactForm from '../../components/organism/contactForm';
import ContactInfo from '../../components/organism/contactInfo';
import './index.css'
import { ScrollTo } from '../../hooks/scrollTo';
import ReactGA from "react-ga4";


const Contact : React.FC = () => { 
  ScrollTo({behavior: 'instant'})

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/contact", title: 'Contact' });
  }, [])

  return (
    <div className='container'>
       <HeroSection
        title='Say Hi!' 
        text={[
          `Bored of standard greetings? Me too.\nThat’s why I like to shake things up with a bit of charm and humor.\nReady to dive into a world where design meets delight? Let’s make it happen.\nGet in touch!`
        ]} />

        <div className='contact-container'>
          <ContactForm />
          <ContactInfo />
        </div>
    </div>
  )
}

export default Contact