import React from 'react';
import './index.css'

interface Props {
  title: string;
  text: string | string[]
}

const AboutProject : React.FC<Props> = ({title, text}) => { 
  return (
    <div className='about-project-container'>
      <h3 className='title-ternary about-project-title'>
        {title}
      </h3>
      
      <div className='about-project-text-container'>
        {
          typeof text === 'string' ?
            <p>{text}</p> :
            text.map((item: string, index:number) => {
              return <p key={index} className='about-project-text-item'>{item}</p>
            }
          )
        }
      </div>
    </div>
  )
}

export default AboutProject