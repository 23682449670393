import React from 'react';
import './index.css'

interface Props {
  title: string;
  image: string;
  className?: string;
}

const ProjectTitleImageContainer : React.FC<Props> = ({
  title,
  image,
  className = ''
}) => { 
  console.log(title)

  return (
    <div className={`container project-title-image-container ${className}`}>
      {/* <h3 className='title-ternary project-title-image-title'>{title}</h3> */}
      <img className='project-title-image-image' src={image} alt={'project-image'} />
    </div>
  )
}

export default ProjectTitleImageContainer