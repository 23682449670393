/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import './index.css'

interface Props {
  backgroundColor?: string;
  title?: string  
  children?: JSX.Element;
  type?: "submit" | "button" | "reset";
  disabled?: boolean;

  onClick?: () => void;
}

const Button : React.FC<Props> = ({
  backgroundColor = '#000',
  onClick = () => {},
  title= 'See more',
  children,
  type = 'button',
  disabled = false
}) => { 
  return (
    <button disabled={disabled}  type={type} className='button-container' style={{backgroundColor, opacity: disabled ? 0.2 : 1}} onClick={onClick}>
      {
        children ?
        children :
        <p>{title}</p>
      }
    </button>
  )
}

export default Button