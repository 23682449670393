import React, { useEffect } from 'react';
import HeroSection from '../../components/organism/heroSection';
import QuoteCards from '../../components/organism/quoteCards';
import GetInTouch from '../../components/organism/getInTouch';
import { ScrollTo } from '../../hooks/scrollTo';
import QuickFacts from '../../components/organism/facts';
import ReactGA from "react-ga4";

const Home : React.FC = () => { 
  ScrollTo({top: 0, behavior: 'instant' })

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/about", title: 'About me' });
  }, [])



  return (
    <div>
      <div className='container'>
        <HeroSection 
          isHome
          title='Hello,' 
          text={[
            `I'm Antonija,\nyour friendly neighborhood UX/UI designer!\nI'm based in Bjelovar and have been living here since forever.`,
            `I make pixels dance, buttons sing, and interfaces smile. Think of me as the DJ of design, mixing creativity and functionality to get your users grooving.\nWhile I may not be able to solve world hunger (yet), I can definitely make your digital experience as smooth as butter on a warm croissant. 🥐 `,
            `After spending countless hours mastering the art of pixels and user empathy, I decided to add some serious credibility to my obsession. Enter the Google UX/UI Certificate, my official stamp of approval, proudly hanging on my wall (okay, maybe it's digitally saved in my drive, but you get the idea).`,
            `As any designer will tell you, looking back at work from a couple of years ago can be like finding an old hairstyle choice — we’ve all been there, right? But that’s the beauty of the journey: constantly growing, learning, and striving for better.`,
            `If we team up, rest assured, my latest design will be my most dazzling yet. Your project will be the crown jewel of my portfolio—practically a masterpiece in the making (wink, wink 😉).`
        ]} />
      </div>

      <QuickFacts />

      <div className='container'>
        <QuoteCards />

        <GetInTouch />
      </div>

    </div>
   
  )
}

export default Home