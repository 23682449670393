import React from 'react';
import './index.css'

interface Props {
  number: number
}

const NumberIcon : React.FC<Props> = ({number}) => { 
  return (
    <div className='number-circle'>
      <p>
        {number}
      </p>
    </div>
  )
}

export default NumberIcon