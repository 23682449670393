import React from 'react';
import QuoteCard from '../../molecule/quoteCard';
import './index.css'
import TwitterIcon from '../../atom/twitterIcon';


const QuoteCards : React.FC = () => { 
  return (
    <div className='quote-cards-container'>
      <QuoteCard className='quote-card-left' author="- Martin LeBlanc" text='"A user interface is like a joke. If you have to explain it, its not that good."' icon={<TwitterIcon />}/>
      <QuoteCard className='quote-card-center' author='- Robert L. Peters' text='"Design creates culture. Culture shapes values. Values determine the future."' icon={<TwitterIcon dark />}/>
      <QuoteCard className='quote-card-right' author='- Jared Spool' text='"Good design, when its done well becomes invisible. Its only when its done poorly that we notice it."' icon={<TwitterIcon />}/>
    </div>
  )
}

export default QuoteCards