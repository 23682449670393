import React from 'react';
import './index.css'

interface Props {
  className?: string;
  icon: JSX.Element;
  author: string;
  text: string
}

const QuoteCard : React.FC<Props> = ({className= '', icon, author, text}) => { 
  return (
    <div className={`quote-card-container ${className}`}>
      {
        icon
      }

      <p>
        {text}
      </p>
      <p>
        {author}
      </p>
    </div>
  )
}

export default QuoteCard