import React from 'react';
import SocialInfo from '../../molecule/socialInfo';
import './index.css';
import EmailIcon from '../../atom/emailIcon';
import LinkedInIcon from '../../atom/linkedInIcon';

const ContactInfo : React.FC = () => { 
  return (
    <div className='contact-info-container'>
      <div className='contact-info-image-container' style={{ backgroundColor: 'gray'}}>
        <img className='contact-info-image' src={require('../../../assets/contact-profile-picture.png')} alt='hero image'/>
      </div>
      <div className='contact-info-text-container'>
        <h3>Antonija Luketić</h3>
        <h4>UX-UI Dizajner</h4>

        <div className='social-container'>
          <SocialInfo url='mailto://antonijaluketic511@gmail.com' text='antonijaluketic511@gmail.com' icon={<EmailIcon />}/>
          <SocialInfo url='https://www.linkedin.com/in/antonija-luketi%C4%87-02b54126b/' text='Antonija Luketić' icon={<LinkedInIcon />}/>
        </div>
      </div>
    </div>
  )
}

export default ContactInfo