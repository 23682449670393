import React from 'react';
import './index.css'

interface Props {
  title: string;
  text: string | string[];
  className?: string;
}

const ProjectText : React.FC<Props> = ({title, text, className}) => { 
  return (
    <div className={`container project-text-container ${title.toLowerCase() === 'mockups' ? 'project-text-container-padding' : ''} ${className}`}>
      <h3 className='title-secondary'>{title}</h3>
      <div className='project-text-text-container'>
        {
          typeof text === 'string' ?
            <p className='project-text-text-item'>{text}</p> :
            text.map((item, index) => {
              return <p key={index} className='project-text-text-item'>{item}</p>
            })
        }
      </div>
    </div>
  )
}

export default ProjectText