import React from 'react';
import './index.css'

interface Props {
  label: string;
  inputType: 'text' | 'email' | 'textarea';
  name?: string
}

const InputField : React.FC<Props> = ({
  label = 'Label',
  inputType,
  name=""
}) => { 
  return (
    <div className='input-field-container'>
      <p>{label}</p>
      {
        inputType !== 'textarea' ?
        <input name={name} placeholder={label} type={inputType} />:
        <textarea name={name} placeholder={label} rows={7}/>
      }
    </div>
  )
}

export default InputField