import React from 'react';
import './index.css'
import { ITitleText } from '../../../../interface';
// import UserResearchElement from '../../../molecule/userResearchElement';
import UserResearchElementV2 from '../../../molecule/userResearchElement/userResearchElementV2';

interface Props {
  data: ITitleText[]
}

const UserResearch : React.FC<Props> = ({data}) => { 
  return (
    <div className='user-research-container'>
      <div className='container'>

        <h1 className='title-ternary user-research-title'>
          User research: Pain points
        </h1>

        <div className='user-research-elements-container'>
          <UserResearchElementV2 number={1} title={data[0].title} text={data[0].text as string} />
          <UserResearchElementV2 number={2} title={data[1].title} text={data[1].text as string} />
          <UserResearchElementV2 number={3} title={data[2].title} text={data[2].text as string} />
        </div>
      </div>

    </div>
  )
}

export default UserResearch

