import React from 'react';
import './index.css'
import { Link } from 'react-router-dom';


const GetInTouch : React.FC = () => { 
  return (
   <div className='get-in-touch-container'>
    <p>Interested to work with me?</p>
    <h1>
      <Link to='/contact'>
          Get in touch
      </Link>
    </h1>
   </div>
  )
}

export default GetInTouch