import emailjs from '@emailjs/browser'
import {  FormEvent, RefObject } from 'react';

interface IEmailResponse {
    status: string,
    statusText: string,
    statusCode: number
}

export const sendEmail = (e: FormEvent, form: RefObject<HTMLFormElement>) : Promise<IEmailResponse> => {
    e.preventDefault()

    let resp: IEmailResponse;

  return emailjs
    .sendForm('service_dyohpos', 'template_r6c5wve', form.current!, 'MyQGY88hp7_8-vwvc')
    .then(
      () => {
        console.log('SUCCESS!');

        resp = {
          status: 'SUCCESS',
          statusText: 'Thanks for your message. I\'ll get back to you as soon as possible.',
          statusCode: 200
        }

        return resp
      },
      (error) => {
        console.log('FAILED...' + error.text);

        resp = {
          status: 'ERROR',
          statusText: 'Oops, an error has occured! Try again later.',
          statusCode: 503
        }

        return resp

      },
    );
};
