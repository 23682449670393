import React, { useEffect, useRef, useState } from 'react';
import './index.css'

interface Props {
  text: string
  urlTitle: string
  url: string;
  isFirst?: boolean
}

const ProjectUrl : React.FC<Props> = ({
  text,
  urlTitle,
  url,
  isFirst = false
}) => { 
  const [lineWidth, setLineWidth] = useState<number>(0)
  const urlTitleRef = useRef<HTMLHeadingElement>(null) 

  useEffect(() => {
    if(urlTitleRef.current) {
      setLineWidth(urlTitleRef.current?.clientWidth)
    }
  }, [urlTitleRef])
 
  return (
    <div className='project-url-container' style={!isFirst ? {paddingTop: 0} : {}}>
      <div className='container project-url-content-container'>
        <p>{text}</p> 

        <div>
          <h4 ref={urlTitleRef} className='title-ternary project-url-url-title'>
            <a href={url} target='_blank' rel="noreferrer">
              {urlTitle}
            </a> 
          </h4>
          <div className='project-url-line' style={{width: lineWidth}}/>
        </div>
      </div>
    </div>
  )
}

export default ProjectUrl