import React from 'react';
import { ITitleBulletList } from '../../../../interface';
import './index.css'
import { getWindowWidth } from '../../../../hooks/getWindowWidth';

interface Props {
  data: ITitleBulletList
}

const Impact : React.FC<Props> = ({data}) => { 
  const {windowWidth} = getWindowWidth()

  return (
    <div className='impact-container'> 
      <div className={`${windowWidth >= 576 ? 'container' : ''} impact-content-container`}>

        <h4 className='title-ternary'>{data.title}</h4>
        <div className='impact-text-container'>
          {
            data.bulletList.map((el: string, i: number) => {
              return <p key={i}>{el}</p>
            })
          }
        </div>
      </div>

    </div>
  )
}

export default Impact