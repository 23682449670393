export const changeNavbarRoute = (route: "/" | '/work' | '/contact' | string ) => {
  const aboutLink = document.querySelectorAll('.about')
  const workLink = document.querySelectorAll('.work')
  const contactLink = document.querySelectorAll('.contact')

  if(!aboutLink || !workLink || !contactLink) return;

  switch(route) {
    case '/':
      aboutLink.forEach(element => {
        element.classList.add('active');
      });
      
      workLink.forEach(element => {
        element.classList.remove('active');
      });

      contactLink.forEach(element => {
        element.classList.remove('active');
      });

      // workLink.classList.remove('active');
      // contactLink.classList.remove('active');
      break;

      case "/work":
        workLink.forEach(element => {
          element.classList.add('active');
        });
  
        aboutLink.forEach(element => {
          element.classList.remove('active');
        });
        
        contactLink.forEach(element => {
          element.classList.remove('active');
        });
        break;


      case "/contact":
        contactLink.forEach(element => {
          element.classList.add('active');
        });

        workLink.forEach(element => {
          element.classList.remove('active');
        });
  
        aboutLink.forEach(element => {
          element.classList.remove('active');
        });
        break;

      default:
        contactLink.forEach(element => {
          element.classList.remove('active');
        });
        
        workLink.forEach(element => {
          element.classList.remove('active');
        });
  
        aboutLink.forEach(element => {
          element.classList.remove('active');
        });
        break;
  }

}